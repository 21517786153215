<template>
  <section class="user-profile" v-if="user">
    <h3 class="marketplace-title user-profile__title">
      {{
        user_profile_translations.title ?
          (insert_variables_into_string(user_profile_translations.title, [{ value: user.info.name, type: "name" }]))
          : $translate("user_profile.title", { name: user.info.name })
      }}
    </h3>

    <div class="user-profile__menu">
      <div
        v-for="{ title, button_value, link } in menu_buttons"
        :key="title"
        class="user-profile__menu-button"
        @click="open_content(button_value)"
      >
        <router-link v-if="link" :to="link">{{ title }}</router-link>
        <span v-else>{{ title }}</span>
        <arrow />
      </div>
    </div>

    <div class="user-profile__content">
      <div v-if="loading" class="user-profile__loading">
        <Loader />
      </div>
      <div v-if="!loading && active_content === 'overview'" class="user-profile__overview">
        <h3>{{ user_profile_translations.account_overview_title || $translate("user_profile.account_overview_title") }}:</h3>

        <h4>{{ user_profile_translations.number_of_projects || $translate("user_profile.number_of_projects") }}: {{ user.number_of_projects }}</h4>
        <h4>{{ user_profile_translations.number_of_managed_users || $translate("user_profile.number_of_managed_users") }}: {{ user.number_of_managed_users }}</h4>
        <h4>{{ user_profile_translations.number_of_products_purchased || $translate("user_profile.number_of_products_purchased") }}: {{ number_of_purchased_products }}</h4>
      </div>
      <div v-else-if="!loading && active_content === 'orders'" class="user-profile__orders">
        <h3>{{ user_profile_translations.orders_title || $translate("user_profile.orders_title") }}: <reload @click.native="get_user_info"/></h3>
        <div
          v-for="({
            id, order_number, created_at, shipping_address, currency, subtotal_price,
            total_price, total_tax, shipping_lines, fulfillment_status, line_items
          }, index) in get_orders"
          :key="id"
          class="user-profile__order"
        >
          <div class="user-profile__order-top">
            <p>{{ user_profile_translations.order_number || $translate("user_profile.order_number") }} {{ order_number || (index + 1) }}</p>
            <p>{{ user_profile_translations.order_status || $translate("user_profile.order_status") }}: <span>{{ fulfillment_status }}</span></p>
            <p>{{ get_formated_date(created_at) }}</p>
          </div>
          <div class="user-profile__order-middle">
            <div>
              <p>{{ user_profile_translations.delivery_to_title || $translate("user_profile.delivery_to_title") }}</p>
              <section>
                <span>{{ shipping_address.address1 }}</span>
                <span>{{ shipping_address.address2 || shipping_address.city }}</span>
                <span v-if="shipping_address.address2">{{ shipping_address.city }}</span>
                <span v-else>{{ shipping_address.zip }}, {{ shipping_address.country }}</span>
                <span v-if="shipping_address.address2">{{ shipping_address.zip }}, {{ shipping_address.country }}</span>
              </section>
              <section>
                <span>{{ shipping_address.name }}</span>
                <span>Tel: {{ shipping_address.phone }}</span>
                <span>Email: {{ shipping_address.email }}</span>
              </section>
            </div>
            <div>
              <p>{{ user_profile_translations.payment_title || $translate("user_profile.payment_title") }}</p>
              <span>
                {{ user_profile_translations.subtotal || $translate("user_profile.subtotal") }}: {{ currency_symbol(currency) }} {{ subtotal_price }}
              </span>
              <span>
                {{ user_profile_translations.shipping_fee || $translate("user_profile.shipping_fee") }}: {{ currency_symbol(currency) }} {{ shipping_lines[0].price }}
              </span>
              <span>
                {{ user_profile_translations.tax || $translate("user_profile.tax") }}: {{ currency_symbol(currency) }} {{ total_tax }}
              </span>
              <span>
                <b>{{ user_profile_translations.total || $translate("user_profile.total") }}: {{ currency_symbol(currency) }} {{ total_price }}</b>
              </span>
            </div>
          </div>
          <div>
            <div
              v-for="{ image, variant_id, title, variant_options, price, quantity } in line_items"
              :key="variant_id"
              class="user-profile__product"
            >
              <img :src="image" :alt="title">
              <div class="user-profile__product-info">
                <p>{{ title }}</p>
                <p><b>{{  currency_symbol(currency) }} {{ price }}</b> x {{ quantity }}</p>
              </div>
              <div v-if="variant_options[0].value !== default_variant_title" class="user-profile__product-options">
                <span>{{ user_profile_translations.options || $translate("user_profile.options") }}</span>
                <div
                  v-for="({ name, value }, ind) in variant_options"
                  :key="ind"
                >
                  {{ name }}:
                  <span
                    class="user-profile__product-option"
                    :style="{
                      backgroundColor: is_value_a_color(value) ? value : 'transparent'
                    }"
                    :class="{
                      'user-profile__product-option--not-color': !is_value_a_color(value),
                    }"
                  >
                    {{ is_value_a_color(value) ? "" : value }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="user-profile__order-bottom">
            <p v-if="is_marketplace_environment">
              {{ user_profile_translations.seller || $translate("user_profile.seller") }}:
              <a v-if="get_project_info_from_product(line_items).project_domain" :href="`http://${get_project_info_from_product(line_items).project_domain}`" target="_blank" rel="noopener noreferrer">
                {{ get_project_info_from_product(line_items).project_name }}
              </a>
              <span v-else>{{ get_project_info_from_product(line_items).project_name }}</span>
            </p>
            <p v-else/>
            <router-link :to="`/order/${id}/${get_project_info_from_product(line_items).project_id}`">
              <div>{{ user_profile_translations.show_order_detail || $translate("user_profile.show_order_detail") }}</div>
            </router-link>
          </div>
        </div>
        <h4 v-if="!get_orders.length">{{ $translate("user_profile.no_orders") }}</h4>
      </div>
      <div v-else-if="!loading && active_content === 'profile'" class="user-profile__profile">
        <h3>{{ user_profile_translations.profile_title || $translate("user_profile.profile_title") }}:</h3>
        <div
          v-for="{ title, key, type, values } in inputs"
          :key="key"
          class="user-profile__profile-input"
        >
          <p>{{ title }}</p>
          <select v-if="type" v-model="input_values[key]">
            <option v-for="{ code, name } in values" :key="name" :value="code">
              {{ name }}
            </option>
          </select>
          <input
            v-else
            v-model="input_values[key]"
            type="text"
          >
        </div>
        <div
          class="marketplace-button marketplace-button--basic"
          @click="update_user_data"
        >{{ user_profile_translations.update || $translate("user_profile.update") }}</div>
        <h3>{{ user_profile_translations.password_title || $translate("user_profile.password_title") }}:</h3>
        <div class="user-profile__profile-passwords">
          <p>{{ user_profile_translations.old_password || $translate("user_profile.old_password") }}</p>
          <input type="password" autocomplete="new-password" v-model="old_password">
          <p>{{ user_profile_translations.new_password || $translate("user_profile.new_password") }}</p>
          <input type="password" autocomplete="new-password" v-model="password">
          <p>{{ user_profile_translations.new_password_again || $translate("user_profile.new_password_again") }}</p>
          <input type="password" autocomplete="new-password" v-model="password_again">
          <div
            class="marketplace-button marketplace-button--basic"
            @click="update_password"
          >{{ user_profile_translations.change_password || $translate("user_profile.change_password") }}</div>
        </div>
        <p
          v-if="error_message || success_message"
          class="user-profile__message"
          :class="{ 'user-profile__message--error': error_message }"
        >{{ error_message || success_message }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import Loader from "@/applications/Shared/components/loader/index.vue"
import { CLIENT_STORE } from "../../Client/constants/other"
import { UPDATE_GLOBAL_LOADER, UPDATE_GLOBAL_USER } from "../../../store"
import insert_variables_into_string from "../../Client/methods/insert_variables_into_string"
import { fetch_user_profile } from "../methods/ajax/endpoints"
import { currency_signs, SHARED_STORE } from "../constants/other"
import is_value_a_color from "../methods/is_value_a_color"
import countries from "../../../data/countries"
import usa_states from "../../../data/usa_states"
import canada_states from "../../../data/canada_states"
import { update_user_info } from "../../Admin/constants/endpoints/firebase"
import email_validator from "../../Admin/methods/email_validator"
import reload from "./icon_components/reload"
import arrow from "./icon_components/arrow"
import { LOG_OUT_USER } from "../stores/Shared/constants"
import { default_variant_title } from "../../../data/other_constants"

export default {
  components: {
    Loader,
    reload,
    arrow
  },
  data() {
    return {
      loading: false,
      active_content: "orders",
      menu_buttons: [],
      inputs: [],
      password: "",
      password_again: "",
      old_password: "",
      error_message: "",
      success_message: "",
      input_values: {},
      default_variant_title
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language"
    ]),
    ...mapState([
      "user",
      "is_marketplace_environment"
    ]),
    user_profile_translations() {
      return this.translations[this.selected_language] ?
        this.translations[this.selected_language].user_profile : {}
    },
    number_of_purchased_products() {
      return this.user && this.user.orders ? Object.values(this.user.orders).flatMap(({ line_items }) => line_items).length : 0
    },
    get_orders() {
      return Object.values(this.user?.orders || {})
    }
  },
  created() {
    if (!this.user) return this.$router.push("/login")

    this.menu_buttons = [
      {
        title: this.user_profile_translations.order_history || this.$translate("user_profile.order_history"),
        button_value: "orders"
      },
      {
        title: this.user_profile_translations.settings || this.$translate("user_profile.settings"),
        button_value: "profile"
      },
      {
        title: this.user_profile_translations.account_overview || this.$translate("user_profile.account_overview"),
        button_value: "overview"
      },
      {
        title: this.user_profile_translations.logout || this.$translate("user_profile.logout"),
        button_value: "logout"
      },
      {
        title: this.user_profile_translations.log_into_admin || this.$translate("user_profile.log_into_admin"),
        link: "/external/log_into_admin"
      },
    ]
    this.inputs = [
      {
        title: this.user_profile_translations.name || this.$translate("user_profile.name"),
        key: "name"
      },
      {
        title: this.user_profile_translations.email || this.$translate("user_profile.email"),
        key: "email"
      },
      {
        title: this.user_profile_translations.shipping_country || this.$translate("user_profile.shipping_country"),
        key: "country",
        type: "select",
        values: countries
      },
      {
        title: this.user_profile_translations.state || this.$translate("user_profile.state"),
        key: "state",
        type: "select",
        values: [...usa_states, ...canada_states]
      },
      {
        title: this.user_profile_translations.phone || this.$translate("user_profile.phone"),
        key: "phone_number"
      }
    ]

    this.synchronize_user_info_inputs()
  },
  methods: {
    ...mapMutations({
      update_user: UPDATE_GLOBAL_USER,
      update_loader: UPDATE_GLOBAL_LOADER
    }),
    ...mapActions(SHARED_STORE, {
      log_out_user: LOG_OUT_USER
    }),
    insert_variables_into_string,
    is_value_a_color,
    synchronize_user_info_inputs() {
      this.input_values = {
        name: this.user.info.name,
        email: this.user.info.email,
        country: this.user.info.country,
        state: this.user.info.state,
        phone_number: this.user.info.phone_number
      }
    },
    async authenticate_password() {
      const credentials = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        this.old_password
      )

      try {
        await firebase.auth().currentUser.reauthenticateWithCredential(credentials)

        return true
      } catch (error) {
        return false
      }
    },
    async get_user_info() {
      this.loading = true
      
      const { data: { info, ...rest } } = await fetch_user_profile({ user_id: this.user.info.uid })

      this.update_user({ ...rest, info: { ...info, uid: this.user.info.uid } })
      this.loading = false
    },
    async open_content(value) {
      if (value === "logout") {
        await this.log_out_user({ $router: this.$router, route: "/login" })
      }

      this.active_content = value || this.active_content
    },
    currency_symbol(currency) {
      return currency_signs[currency] || currency
    },
    get_formated_date(unformatted_date) {
      return new Date(unformatted_date).toDateString()
    },
    get_project_info_from_product(items) {
      return items[0]
    },
    async update_password() {
      this.error_message = ""
      this.loading = true
      const old_password_match = await this.authenticate_password()

      if (!old_password_match || this.password_again !== this.password) {
        this.error_message = this.user_profile_translations[!old_password_match ? "wrong_passord" : "passwords_dont_match"] ||
          this.$translate(`user_profile.${!old_password_match ? "wrong_passord" : "passwords_dont_match"}`)
        return this.loading = false
      }

      try {
        firebase.auth().currentUser.updatePassword(this.password_again)
      } catch ({ message }) {
        this.error_message = message
        return this.loading = false
      }

      this.password = ""
      this.password_again = ""
      this.old_password = ""
      this.show_succes_update_message()
      this.loading = false
    },
    async update_user_data() {
      this.error_message = ""
      this.loading = true

      this.input_values = Object
        .entries(this.input_values)
        .reduce((tot, [key, val]) => val && val !== this.user.info[key] ? { ...tot, [key]: val } : tot, {})

      if (
        this.input_values.email &&
        !email_validator(this.input_values.email)
      ) return this.error_message = this.user_profile_translations.incorrect_email_format
        || this.$translate("user_profile.incorrect_email_format")

      try {
        if (this.input_values.email) await firebase.auth().currentUser.updateEmail(this.new_email)
        await update_user_info({
          user_info: {
            ...this.user.info,
            ...this.input_values
          },
          id: this.user.info.uid
        })
      } catch ({ message }) {
        this.loading = false
        return this.error_message = message
      }

      await this.get_user_info()
      this.synchronize_user_info_inputs()
      this.show_succes_update_message()
    },
    show_succes_update_message() {
      this.success_message = this.user_profile_translations.update_succes_message || this.$translate("user_profile.update_succes_message")
      setTimeout(() => this.success_message = "", 5000);
    }
  }
}
</script>

<style lang="scss">
  @use "../../../styles/_global-constants" as *;
  @use "../../../styles/_marketplace-constants" as *;
  @use "../../../styles/marketplace" as *;

  .user-profile {
    display: grid;
    grid-template-areas:
      "title title title"
      "menu content content";
    grid-template-columns: 200px calc(100% - 200px - #{$spacing--medium * 2});
    column-gap: $spacing--medium;
    row-gap: $spacing--medium;
    padding: $spacing--large 0;

    @media (max-width: $maximum-width) {
      padding: $spacing--large $spacing--small;
      column-gap: $spacing--small;
      row-gap: $spacing--small;
    }

    @media (max-width: $tablet) {
      padding: $spacing--medium $spacing--small;
      grid-template-areas:
        "title title title title"
        "menu content content content";
      grid-template-columns: 150px calc(100% - 150px - #{$spacing--small * 3});
    }

    @media (max-width: $tablet--small) {
      grid-template-areas:
        "title"
        "menu"
        "content";
      grid-template-columns: 1fr;
    }

    &__title {
      grid-area: title;
    }

    &__menu {
      grid-area: menu;
      border: 1px solid $grey--light;
      border-radius: $border-radius;
      height: fit-content;

      &-button {
        position: relative;
        padding: $spacing--small;
        border-bottom: 1px solid $grey--light;
        cursor: pointer;

        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: $tiny-icon;
          fill: $grey;

          @media (max-width: $tablet) {
            width: 10px;
            right: 6px;
          }

          @media (max-width: $tablet--small) {
            width: $tiny-icon;
            right: 10px;
          }
        }

        @media (max-width: $tablet) {
          font-size: $font-size--medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a {
          display: block;
          padding: $spacing--small;
        }

        &:last-child {
          padding: 0;
          border: none;
        }
      }
    }

    &__loading {
      position: relative;
      height: 300px;

     .loader-wave {
        position: absolute;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        z-index: 10;

        &-inner {
          &.loading {
            transform: scale(1.2) !important;
          }
        }
      }
    }

    &__profile {
      &-input {
        display: inline-block;
        width: calc(50% - 3px);
        padding-right: $spacing--small;
        margin-bottom: $spacing--small;

        &:nth-child(odd) {
          padding-right: 0;
          padding-left: $spacing--small;
        }

        @media (max-width: $mobile) {
          display: block;
          width: 100%;
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
      }

      &-passwords {
        width: 50%;

        input {
          width: 100%;
        }

        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      p {
        margin: 0 0 $spacing--tiny;
        font-size: $font-size--small;
      }

      .marketplace-button {
        @media (max-width: $mobile) {
          display: block !important;
          margin: 0 auto $spacing--small;
          text-align: center;
        }
      }

      input, select {
        display: block;
        width: 100%;
        margin: 0 0 $spacing--tiny;
        border: none;
        border-bottom: 1px solid $grey--light;
        color: $grey--dark;
        line-height: 25px;
        height: 25px;
        font-size: $font-size--small;
        background-color: transparent;
        transition: $default-transition;

        &:hover, &:active, &:focus {
          border-color: $grey;
          outline: none;
        }

        &::placeholder {
          text-align: left;
          color: $grey;
        }
      }
    }

    &__content {
      grid-area: content;
      overflow: auto;
      max-height: calc(100vh - #{$menu-height} - 200px);
      padding: $spacing--small;
      border: 1px solid $grey--light;
      border-radius: $border-radius;

      h3 {
        margin: 0 0 $spacing--medium;
        font-weight: bold;

        svg {
          display: inline-flex;
          vertical-align: middle;
          float: right;
          fill: $grey--dark;
          cursor: pointer;

          @keyframes half_rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-180deg);
            }
          }

          &:hover {
            animation: half_rotate .3s ease-in-out;
          }
        }
      }
    }

    &__order {
      margin-bottom: $spacing--medium;
      border: 1px solid $grey--light;
      border-radius: $border-radius;

      &:last-child {
        margin: 0;
      }

      &-top {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid $grey--light;

        @media (max-width: $tablet) {
          grid-template-columns: 1fr 1fr;
          padding: $spacing--small;
          row-gap: $spacing--small;
        }

        p {
          padding: 0 $spacing--small;
          margin: 0;
          line-height: 48px;
          font-size: $font-size--medium;

          span {
            text-transform: uppercase;
          }

          &:first-child {
            font-weight: 500;
          }
          &:nth-child(2) {
            text-align: center;
          }
          &:last-child {
            text-align: right;
            color: $grey;
            font-size: $font-size--small;
          }

          @media (max-width: $tablet) {
            padding: 0;
            line-height: normal;
            text-align: left !important;
          }
        }
      }

      &-middle {
        display: grid;
        border-bottom: 1px solid $grey--light;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "address address payment";

        @media (max-width: $tablet) {
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "address payment";
          word-break: break-word;
        }

        div {
          grid-area: address;
          padding: $spacing--small;

          &:last-child {
            grid-area: payment;

            span {
              display: block;
              width: 100%;
            }
          }
        }

        section {
          display: inline-block;
          width: calc(50% - 3px);
          vertical-align: top;

          @media (max-width: $tablet) {
            display: block;
            width: 100%;
          }
        } 

        span {
          display: block;
          font-size: $font-size--small;
          margin-bottom: 5px;
        }

        p {
          margin: 0 0 $spacing--tiny;
          color: $grey;
          font-size: $font-size--tiny;
        }
      }

      &-bottom {
        display: grid;
        font-size: $font-size--small;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 0 $spacing--small;

        a, div {
          color: $brand-color--dark;
        }

        div {
          text-align: right;
        }
      }
    }

    &__product {
      $image-size: 50px;
      $image-size-mobile: 100px;
      display: grid;
      padding: $spacing--small;
      grid-template-columns: #{$image-size} 200px calc(100% - 280px);
      grid-template-areas: "image info options";
      column-gap: $spacing--small;
      border-bottom: 1px solid $grey--light;


      @media (max-width: $mobile) {
        grid-template-columns: $image-size-mobile calc(100% - #{$image-size-mobile} - #{$spacing--medium});
        grid-template-areas: "image info" "image options";
        row-gap: $spacing--small;
        column-gap: $spacing--medium;
      }

      img {
        grid-area: image;
        width: $image-size;
        height: $image-size;
        object-fit: contain;
        align-self: center;

        @media (max-width: $mobile) {
          width: $image-size-mobile;
          height: $image-size-mobile;
        }
      }

      p {
        margin: 0 0 $spacing--tiny;
      }

      &-info {
        grid-area: info;
        font-size: $font-size--medium;

        p {
          margin: 0;
          font-size: $font-size--medium;

          &:last-child {
            margin-top: $spacing--tiny;
          }
        }
      }

      &-option {
        display: inline-block;
        width: 15px;
        height: 15px;
        vertical-align: middle;

        &--not-color {
          width: auto;
          height: auto;
          text-transform: capitalize;
          border: none;
        }

        &s {
          font-size: $font-size--small;
          grid-area: options;

          & > span {
            display: block;
            margin-bottom: $spacing--tiny;
            font-size: $font-size--tiny;
            color: $grey;
          }
        }
      }
    }

    &__message {
      margin-top: $spacing--small !important;
      padding: $spacing--small;
      text-align: center;
      color: $pure-white;
      border-radius: $border-radius;
      background-color: $green-color;
      font-size: $font-size--medium !important;

      &--error {
        background-color: $alert-color;
      }
    }
  }
</style>
